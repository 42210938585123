
<template>
	<div>
		<div class="banner-wrap">
			<div class="banner">
<!--				<el-image fit="fill" src="http://222.168.10.38:8077/cczcimage/20231012/90935cdbed384074a786b5e8953f7c7d.png" class="banner_img"></el-image>-->
				<video src="http://222.168.10.38:8077/cczcimage/zcvideo.mp4" autoplay muted loop playsinline class="banner_video"></video>
<!--				<video src="https://static.alibabagroup.com/static/fb23a195-4822-4688-9809-db1609ae29c9.mp4" autoplay muted loop playsinline class="banner_video"></video>-->
			</div>
		</div>
		<div class="new-box">
			<div :class="bgClass"></div>
			<div class="new-title" :style="titleStyle" @click="allNews('7')">新闻动态</div>
<!--			<div class="new-title1"  :style="titleStyle" @click="allNews('7')">更多</div>-->
			<div class="news">
				<div style="width: 63.5%;display: flex;flex-wrap: wrap;">
					<div :class="index==0 ? 'news-item-one':'news-item'" v-for="(item, index) in dataList7" :key='index' @click="newRouter(item.id)">
						<el-image
								:class="index==0 ? 'news-one-img':'news-item-img'"
								:src="item.coverImage"
								fit="cover"></el-image>
						<div :class="index==0 ? 'news-one-text-bg':'news-item-text-bg'"></div>
						<div :class="index==0 ? 'news-one-title':'news-item-title'">{{item.title}}</div>
						<div :class="index==0 ? 'news-one-time':'news-item-time'">{{newTime(item.createDate)}}</div>
						<div :class="index==0 ? 'news-one-go':'news-item-go'">〉</div>
					</div>
				</div>
				<div style="width: 35%;margin-left: 1.5%;height: 620px;position: relative;background-color: white;border-radius: 10px;">
					<div style="display: flex;flex-wrap: wrap;width: 70%">
						<div class="newsMenu" :style="menuType =='7'?'color: #007cc7':''" @click="getDataList7()">长仲动态</div>
						<div class="newsMenu" :style="menuType =='8'?'color: #007cc7':''" @click="getAllDatalist('8')">重要发文</div>
						<div class="newsMenu" :style="menuType =='9'?'color: #007cc7':''" @click="getAllDatalist('9')">通知公告</div>
					</div>
					<div style="position:absolute;right: 20px; top: 20px;font-weight: 700;font-size: 14px;cursor: pointer;z-index: 1000" @click="allNews(menuType)">更多</div>
					<div class="list_item1" v-for="(item, index) in dataListAll" :key='index' @click="newRouter(item.id)">
						<div class="list_item1_time1">{{item.time1}}</div>
						<div class="list_item1_time2">{{item.time2}}</div>
						<div class="list_item1_title">{{item.title}}</div>
					</div>
				</div>
			</div>
		</div>
		<el-image src="http://222.168.10.38:8077/cczcimage/20231102/a464810a50f3464f9caacc1fdf63417f.png" style="width: 80%; margin-left: 10%"></el-image>
		<div class="ksfw">
			<div class="ksfw_title">快速服务</div>
			<div class="ksfw_list">
				<div class="ksfw_list_item"  v-for="(item, index) in ksfwList" :key='index' @click="ksfw(index)">
					<el-image :src="item.img1" class="ksfw_list_item_img"></el-image>
					<el-image :src="item.img2" class="ksfw_list_item_img1"></el-image>
					<div class="ksfw_list_item_title">{{item.title}}</div>
					<div class="ksfw_list_item_text">{{item.text}}</div>
				</div>
			</div>
		</div>
		<div class="four">
			<div class="four-item">
				<div class="list-title" @click="allNews('10')">法律园地</div>
				<div class="list-title-min">LEGAL GARDEN</div>
				<div class="list_item" v-for="(item, index) in dataList10" :key='index' @click="newRouter(item.id)">
					<div class="list_item_title">{{item.title}}</div>
					<div class="list_item_time">{{newTime(item.createDate)}}</div>
				</div>
			</div>
			<div class="four-item">
				<div class="list-title" @click="allNews('11')">党建文化</div>
				<div class="list-title-min">PARTY CULTURE</div>
				<div class="list_item" v-for="(item, index) in dataList11" :key='index' @click="newRouter(item.id)">
					<div class="list_item_title">{{item.title}}</div>
					<div class="list_item_time">{{newTime(item.createDate)}}</div>
				</div>
			</div>
			<div class="four-item">
				<div class="list-title" @click="allNews('12')">案例分析</div>
				<div class="list-title-min">CASE ANALYSIS</div>
				<div class="list_item" v-for="(item, index) in dataList12" :key='index' @click="newRouter(item.id)">
					<div class="list_item_title">{{item.title}}</div>
					<div class="list_item_time">{{newTime(item.createDate)}}</div>
				</div>
			</div>
			<div class="four-item">
				<div class="list-title" @click="allNews('13')">仲裁员在线培训</div>
				<div class="list-title-min">ARBITRATOR TRAINING</div>
				<div class="list_item" v-for="(item, index) in dataList13" :key='index' @click="newRouter(item.id)">
					<div class="list_item_title">{{item.title}}</div>
					<div class="list_item_time">{{newTime(item.createDate)}}</div>
				</div>
			</div>
		</div>
		<div class="ptfu">
			<el-image src="http://222.168.10.38:8077/cczcimage/20231013/ca8dd2afd08d4d96af96ff4f87a73dd8.png" class="ptfu_bg"></el-image>
			<div class="ptfu_title">平台服务</div>
			<div class="ptfu_list">
				<div class="ptfu_list_item"  v-for="(item, index) in ptfuList" :key='index' @click="ptfw(index)">
					<el-image :src="item.img" class="ptfu_list_item_img"></el-image>
					<div class="ptfu_list_item_title">{{item.title}}</div>
					<div class="ptfu_list_item_text">{{item.text}}</div>
				</div>
			</div>
			<div class="ptfu_phone">0431-81120000</div>
			<el-image src="http://222.168.10.38:8077/cczcimage/20231013/8808392ab7004b90873bf893ad251e26.png" class="ptfu_logo"></el-image>
		</div>
		<div class="zcy">
			<div class="zcy_title">仲裁员简介</div>
			<div class="zcy_form">
				<div class="zcy_form_bg1" @click="allZcy()">
					<el-image
							class="zcy_form_bg1_logo"
							src="http://222.168.10.38:8077/cczcimage/20231103/e66b6b23e1294ca986102bf1942959b9.png"
							fit="cover"></el-image>
					<div class="zcy_form_bg1_text1">仲裁员名册</div>
					<div class="zcy_form_bg1_text2">ARBITRATORS ROSTER</div>
				</div>
				<el-image
						class="zcy_form_img"
						:src="randArbitrator.img"
						fit="cover"></el-image>
				<div class="zcy_form_name">{{randArbitrator.name}}</div>
				<div  class="zcy_form_text">
					<div class="zcy_form_text1">性<span style="opacity:0">啊啊</span>别:&nbsp;&nbsp;&nbsp;&nbsp;{{randArbitrator.sex=='0'?'男': '女'}}</div>
					<div class="zcy_form_text1">出生年月:&nbsp;&nbsp;&nbsp;&nbsp;{{randArbitrator.dateOfBirth}}</div>
					<div class="zcy_form_text1">职<span style="opacity:0">啊啊</span>业:&nbsp;&nbsp;&nbsp;&nbsp;{{randArbitrator.work}}</div>
					<div class="zcy_form_text1">职<span style="opacity:0">啊啊</span>称:&nbsp;&nbsp;&nbsp;&nbsp;{{randArbitrator.level}}</div>
					<div class="zcy_form_text1">政治面貌:&nbsp;&nbsp;&nbsp;&nbsp;{{randArbitrator.political}}</div>
					<div class="zcy_form_text1">能否涉外:&nbsp;&nbsp;&nbsp;&nbsp;{{randArbitrator.isOut=='0'?'否':'是'}}</div>
					<div class="zcy_form_text1">擅长专业:&nbsp;&nbsp;&nbsp;&nbsp;{{randArbitrator.profession}}</div>
				</div>

			</div>
		</div>
		<div class="left_gozc_bg" @click="goZc()">
			<div style="position:absolute;width: 100%; text-align: center;color: white;font-size: 16px; top: 25px; font-weight: 700">在线</div>
			<div style="position:absolute;width: 100%; text-align: center;color: white;font-size: 16px; top: 55px; font-weight: 700">仲裁立案</div>
		</div>
		<el-image src="http://222.168.10.38:8077/cczcimage/20231103/458c16a2ba404a6b851d669a0b3560a9.png" class="left_gozc"></el-image>
		<el-image src="http://222.168.10.38:8077/cczcimage/20231103/1af44f8329694f87b31c6da267bc7984.png" class="left_wx"></el-image>
		<el-image src="http://222.168.10.38:8077/cczcimage/20231020/dace3d88e1df4eb3baee1abead4bed85.jpg" class="left_wx_1"></el-image>
		<el-image src="http://222.168.10.38:8077/cczcimage/20231103/b58e2b07f6c748e9aa8e8523fe4bf70e.png" class="left_dy"></el-image>
		<el-image src="http://222.168.10.38:8077/cczcimage/20231020/f649f0ce5058404ca5affdeb6d8885b1.png" class="left_dy_1"></el-image>
	</div>
</template>
<script>
	export default {
		data () {
			return {
				dataList7: [],
				dataListAll: [],
				dataList10: [],
				dataList11: [],
				dataList12: [],
				dataList13: [],
				menuType: '7',
				top: 0,
				titleStyle: "opacity: 0;",
				bgClass: "bgDown",
				ptfuList: [
					{
						title: "仲裁调解",
						text: "仲裁调解是指在仲裁庭主持下,仲裁当事人在自愿协商、互谅互让基础上达成协议,从而解决纠纷的一种制度",
						img: "http://222.168.10.38:8077/cczcimage/20231013/1231bf44e3af47af985eeef2e6385809.png"
					},
					{
						title: "仲裁指南",
						text: "仲裁受理范围 | 哪些纠纷可以仲裁 | 哪些纠纷不能仲裁",
						img: "http://222.168.10.38:8077/cczcimage/20231013/13847a9e049d4fb1abe072afe0ed1a7e.png"
					},
					{
						title: "仲裁下载",
						text: "法人代表证明书 | 和解协议 | 简化程序申请授权委托书仲裁申请书样式 | 仲裁协议长春仲裁委员会仲裁规则...",
						img: "http://222.168.10.38:8077/cczcimage/20231013/5a18f6f1451e4f3d90cc0dc9a76c6e4e.png"
					}
				],

				ksfwList: [
					{
						title: "在线咨询",
						text: "ONLINE CONSULT",
						img1: "http://222.168.10.38:8077/cczcimage/20231102/4d9abe1711434be78eb86acce19c1982.png",
						img2: "http://222.168.10.38:8077/cczcimage/20231102/904561970c8d488b8fc6aaeee665188c.png",
					},
					{
						title: "网上仲裁",
						text: "ONLINE ARBITRATION",
						img1: "http://222.168.10.38:8077/cczcimage/20231102/8ce8973f2f704bb293fb933102a300da.png",
						img2: "http://222.168.10.38:8077/cczcimage/20231102/1ff0ee6743dc46fe85a83cd8e0c1672c.png",
					},
					{
						title: "仲裁员选择",
						text: "SELECT ARBITRATOR",
						img1: "http://222.168.10.38:8077/cczcimage/20231102/1b6af68044e348fcb2630c31d2ab8c46.png",
						img2: "http://222.168.10.38:8077/cczcimage/20231102/c8bf34545d5c4885b83a1abd1ce4037b.png",
					},
					{
						title: "仲裁流程",
						text: "ARBITRATOR PROCESS",
						img1: "http://222.168.10.38:8077/cczcimage/20231102/2c3d1acfddae4eb4bf6600e31312da7c.png",
						img2: "http://222.168.10.38:8077/cczcimage/20231102/582ec3c233d14b7fa0b7d457ada72c61.png",
					},
					{
						title: "立案流程",
						text: "ONLINE PROCESS",
						img1: "http://222.168.10.38:8077/cczcimage/20231102/006d78ded7fb4e8eb56275ecab9a29e8.png",
						img2: "http://222.168.10.38:8077/cczcimage/20231102/c5c6f28863f74decb4dfb43fef05a0e9.png",
					},
					{
						title: "仲裁费计算",
						text: "ARBITRATOR FEE",
						img1: "http://222.168.10.38:8077/cczcimage/20231102/bbf2ea43cb4a4324b8ddbf30a3a79690.png",
						img2: "http://222.168.10.38:8077/cczcimage/20231102/e12f00896e4a4b73936f1716cd8608ef.png",
					}
				],
				randArbitrator :{}
			}
		},

		components: {

		},

		watch: {
			top(newValue,oldValue){
				if (newValue >= 100) {
					this.titleStyle = "opacity: 1;"
					this.bgClass = "bgUp"
				}
				else {
					this.titleStyle = "opacity: 0;"
					this.bgClass = "bgDown"
				}
			}
		},

		mounted() {
			this.menuType = '7',
			this.getDataList7()
			this.getDataList10()
			this.getDataList11()
			this.getDataList12()
			this.getDataList13()
			this.getRandArbitrator()
			window.addEventListener('scroll', () => {
				this.top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
			})
		},

		methods: {
			//获取随机仲裁员
			getRandArbitrator() {
				this.$axios.get(this.$httpUrl + '/CczcArbitrator/cczcarbitrator/rand',{
					params: {}
				}).then(res => {
					if (res.data.code != 0) {
						return this.$message.error(res.data.data.msg);
					}
					this.randArbitrator = res.data.data
				})
			},

			getAllDatalist(type) {
				this.menuType = type
				var params = {
					page: 1,
					limit: 8,
					type: type,
					order: "desc",
					orderField: 'create_date',
				}
				this.$axios.get(this.$httpUrl + '/CczcArticle/cczcarticle/page',{
					params: params
				}).then(res => {
					if (res.data.code != 0) {
						return this.$message.error(res.data.data.msg);
					}
					this.dataListAll = res.data.data.list
					for (var i in this.dataListAll) {
						this.dataListAll[i].time1 = this.dataListAll[i].createDate.substring(8,10)
						this.dataListAll[i].time2 = this.dataListAll[i].createDate.substring(0,7)
					}
				})
			},

			newTime(time) {
				return time.substring(0,10)
			},

			//获取新闻列表
			getDataList7() {
				this.menuType = '7'
				var params = {
					page: 1,
					limit: 12,
					type: 7,
					order: "desc",
					orderField: 'create_date',
				}
				this.$axios.get(this.$httpUrl + '/CczcArticle/cczcarticle/page',{
					params: params
				}).then(res => {
					if (res.data.code != 0) {
						return this.$message.error(res.data.data.msg);
					}
					this.dataList7 = []
					this.dataListAll = []
					for (var i in res.data.data.list) {
						if (i < 4) {
							this.dataList7.push(res.data.data.list[i])
						}
						else {
							var item = res.data.data.list[i]
							item.time1 =item.createDate.substring(8,10)
							item.time2 = item.createDate.substring(0,7)
							this.dataListAll.push(item)
						}
					}

				})
			},

			getDataList10() {
				var params = {
					page: 1,
					limit: 5,
					type: 10,
					order: "desc",
					orderField: 'create_date',
				}
				this.$axios.get(this.$httpUrl + '/CczcArticle/cczcarticle/page',{
					params: params
				}).then(res => {
					if (res.data.code != 0) {
						return this.$message.error(res.data.data.msg);
					}
					this.dataList10 = res.data.data.list
				})
			},

			getDataList11() {
				var params = {
					page: 1,
					limit: 5,
					type: 11,
					order: "desc",
					orderField: 'create_date',
				}
				this.$axios.get(this.$httpUrl + '/CczcArticle/cczcarticle/page',{
					params: params
				}).then(res => {
					if (res.data.code != 0) {
						return this.$message.error(res.data.data.msg);
					}
					this.dataList11 = res.data.data.list
				})
			},

			getDataList12() {
				var params = {
					page: 1,
					limit: 5,
					type: 12,
					order: "desc",
					orderField: 'create_date',
				}
				this.$axios.get(this.$httpUrl + '/CczcArticle/cczcarticle/page',{
					params: params
				}).then(res => {
					if (res.data.code != 0) {
						return this.$message.error(res.data.data.msg);
					}
					this.dataList12 = res.data.data.list
				})
			},

			getDataList13() {
				var params = {
					page: 1,
					limit: 5,
					type: 13,
					order: "desc",
					orderField: 'create_date',
				}
				this.$axios.get(this.$httpUrl + '/CczcArticle/cczcarticle/page',{
					params: params
				}).then(res => {
					if (res.data.code != 0) {
						return this.$message.error(res.data.data.msg);
					}
					this.dataList13 = res.data.data.list
				})
			},

			//详情页
			newRouter(id){
				this.$router.push({
					path: "/details",
					query: {
						dataId: id
					}
				})
			},

			//更多
			allNews(type) {
				this.$router.push({
					path: "/list",
					query: {
						type: type,
					}
				})
			},

			//仲裁员名册
			allZcy() {
				this.$router.push("/psnlist")
			},

			goZc() {
				window.open("http://122.112.232.88:8705/index.htm")
			},

			//快速服务
			ksfw(index) {
				if (index== 0) {
					this.$alert('该模块正在开发中、敬请期待！', '提示', {
						confirmButtonText: '确定',
						callback: action => {

						}
					});
				}
				else if (index== 1) {
					window.open("http://122.112.232.88:8705/index.htm")
				}
				else if (index== 2) {
					this.$alert('该模块正在开发中、敬请期待！', '提示', {
						confirmButtonText: '确定',
						callback: action => {

						}
					});
				}
				else if (index== 3) {
					this.$router.push({
						path: '/details',
						query: {
							dataId: '1712019816753381378',
						}
					})
				}
				else if (index== 4) {
					this.$router.push({
						path: '/details',
						query: {
							dataId: '1712019617142259714',
						}
					})
				}
				else {
					this.$router.push('/money')
				}
			},

			//平台服务
			ptfw(index) {
				if (index== 0) {
					this.$alert('该模块正在开发中、敬请期待！', '提示', {
						confirmButtonText: '确定',
						callback: action => {

						}
					});
				}
				else if (index== 1) {
					this.$router.push({
						path: '/details',
						query: {
							dataId: '1712019453967056898',
						}
					})
				}
				else {
					this.$router.push('/file')
				}
			}
		}
	}
</script>


<style scoped>
	.banner-wrap {
		position: relative;
		height: 100vh;
		width: 100%;
		z-index: 10;
	}

	.banner {
		position: relative;
		top: 0px;
		width: 100%;
		height: 100vh;
	}

	.banner_img {
		position: absolute;
		width: 25%;
		height: auto;
		top: 40%;
		left: 37.5%;
		z-index: 100;
	}

	.banner_video {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 110%;
		margin:0;
		padding:0;
		z-index: 90;
		object-fit: cover; object-position: 50% 60%;
	}

	.new-box {
		position: relative;
		width: 100%;
		margin-top: -40px;
		z-index: 600;
	}

	.bgUp {
		position: absolute;
		top: -60px;
		left: 0px;
		width: 100%;
		height: 300px;
		background-color: #f5f5f5;
		animation: moveBgUp 1s forwards;
	}

	@keyframes moveBgUp {
		0% {
			/* 定义动画开始的状态 */
			transform: translateY(150px);
		}
		100% {
			/* 定义动画结束的状态 */
			transform: translateY(0px);
		}
	}

	.bgDown {
		position: absolute;
		top: -60px;
		left: 0px;
		width: 100%;
		height: 300px;
		background-color: #f5f5f5;
		animation: moveBgDown 1s forwards;
	}

	@keyframes moveBgDown {
		0% {
			/* 定义动画开始的状态 */
			transform: translateY(0px);
		}
		100% {
			/* 定义动画结束的状态 */
			transform: translateY(150px);
		}
	}

	.new-title {
		position: absolute;
		font-size: 20px;
		font-weight: 600;
		margin-left: 10%;
		border-left-style: solid;
		border-left-width: 5px;
		border-left-color: #007cc7;
		padding-left: 10px;
		top: -45px;
		cursor: pointer;
	}

	.new-title1 {
		position: absolute;
		font-size: 20px;
		font-weight: 600;
		right: 12%;
		top: -45px;
		cursor: pointer;
	}

	.newsMenu {
		margin-left: 20px;
		font-size: 14px;
		font-weight: 700;
		/*line-height: 100px;*/
		margin-top: 20px;
		margin-bottom: 30px;
		cursor: pointer;
	}

	.news {
		width: 80%;
		margin-left: 10%;
		display: flex;
		flex-wrap: wrap;
	}

	.news-item {
		position: relative;
		width: 32%;
		height: 200px;
		border-radius: 10px;
		margin-bottom: 20px;
		margin-left: calc(4% / 4);
		background-color: white;
		overflow: hidden;
	}

	.news-item:hover .news-item-text-bg {
		background-color: #007cc7;
	}

	.news-item:hover .news-item-title {
		color: white;
	}

	.news-item:hover .news-item-time {
		border-top-color: white;
		color: white;
	}

	.news-item:hover .news-item-go {
		color: white;
	}

	.news-item:hover .news-item-img {
		transform: scale(1.1);
		cursor: pointer;
	}

	.news-item-img {
		position: absolute;
		width: 100%;
		height: 60%;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		transition: all 0.3s ease-in-out;
	}

	.news-one-img {
		position: absolute;
		width: 100%;
		height: 80%;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		transition: all 0.3s ease-in-out;
	}

	.news-item-text-bg {
		position: absolute;
		top: 60%;
		width: 100%;
		height: 40%;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		background-color: white;
	}

	.news-one-text-bg {
		position: absolute;
		top: 80%;
		width: 100%;
		height: 20%;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		background-color: #007cc7;
	}

	.news-one-title {
		position: absolute;
		width: 90%;
		left: 5%;
		top: 82%;
		font-size: 14px;
		font-weight: 700;
		color: white;
		word-wrap: break-word;
	}

	.news-item-title {
		position: absolute;
		width: 90%;
		left: 5%;
		top: 65%;
		font-size: 11px;
		font-weight: 700;
		color: black;
		word-wrap: break-word;
	}

	.news-one-time {
		position: absolute;
		width: 90%;
		left: 5%;
		bottom: 0px;
		font-size: 14px;
		font-weight: 700;
		color: white;
		word-wrap: break-word;
		line-height: 33px;
		border-top-style: solid;
		border-top-width: 1px;
		border-top-color: white;
	}

	.news-item-time {
		position: absolute;
		width: 90%;
		left: 5%;
		bottom: 0px;
		font-size: 11px;
		font-weight: 700;
		color: black;
		word-wrap: break-word;
		line-height: 30px;
		border-top-style: solid;
		border-top-width: 1px;
		border-top-color: black;
	}

	.news-one-go {
		position: absolute;
		right: 5%;
		bottom: 0px;
		font-size: 14px;
		font-weight: 700;
		color: white;
		word-wrap: break-word;
		line-height: 30px;
	}

	.news-item-go {
		position: absolute;
		right: 5%;
		bottom: 0px;
		font-size: 11px;
		font-weight: 700;
		color: black;
		word-wrap: break-word;
		line-height: 30px;
	}

	.news-item-one {
		position: relative;
		display: inline-block;
		width: 100%;
		height: 400px;
		border-radius: 10px;
		margin-bottom: 20px;
		background-color:white;
		margin-left: 1%;
		overflow: hidden;
	}

	.news-item-one:hover .news-one-img {
		transform: scale(1.1);
		cursor: pointer;
	}

	.ksfw {
		position: relative;
		width: 80%;
		margin-top: 30px;
		left: 10%;
		height: 300px;
	}

	.ksfw_title {
		position: absolute;
		font-size: 20px;
		font-weight: 600;
		border-left-style: solid;
		border-left-width: 5px;
		border-left-color: #007cc7;
		padding-left: 10px;
	}


	.ksfw_list {
		position: absolute;
		width: 100%;
		top: 15%;
		height: 75%;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	.ksfw_list_item {
		position: relative;
		width: 32%;
		height:50%;
		margin-left: calc(4% / 4);
		overflow: hidden;
		background-color: white;
		cursor: pointer;
		margin-bottom: 1%;
	}

	.ksfw_list_item:hover {
		background-color: #007cc7;
	}

	.ksfw_list_item:hover .ksfw_list_item_img {
		opacity: 0;
	}

	.ksfw_list_item:hover .ksfw_list_item_img1 {
		opacity: 1;
	}

	.ksfw_list_item:hover .ksfw_list_item_title {
		color: white;
	}

	.ksfw_list_item:hover .ksfw_list_item_text {
		color: white;
	}

	.ksfw_list_item_img {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 10%;
		top: 15%;
		opacity: 1;
	}

	.ksfw_list_item_img1 {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 10%;
		top: 15%;
		opacity: 0;
	}

	.ksfw_list_item_title {
		position: absolute;
		line-height: 30px;
		font-size: 18px;
		left: 35%;
		top: 15%;
		font-weight: 700;
	}

	.ksfw_list_item_text {
		position: absolute;
		font-size: 14px;
		width: 80%;
		left: 35%;
		bottom: 20%;
		word-wrap: break-word;
	}

	.four {
		position: relative;
		width: 80%;
		left: 10%;
		background-color: white;
		margin-top: 30px;
		display: flex;
		flex-wrap: wrap;
	}

	.four-item {
		position: relative;
		width: 45%;
		margin-left: calc(5% / 3);
		overflow: hidden;
		margin-bottom: 30px;
	}

	.list-title {
		width: 100%;
		text-align: center;
		font-weight: 700;
		font-size: 18px;
		margin-top: 40px;
		cursor: pointer;
	}

	.list-title:hover {
		color: #007cc7;
	}

	.list-title-min {
		width: 100%;
		text-align: center;
		font-weight: 700;
		font-size: 14px;
		margin-top: 20px;
		margin-bottom: 20px;
		color: #007cc7;
	}

	.list_item {
		position: relative;
		width: 90%;
		left: 5%;
		height: 60px;
		line-height: 60px;
		cursor: pointer;
		border-bottom-style: solid;
		border-bottom-width: 1px;
		border-bottom-color: #007cc7;
	}

	.list_item:hover .list_item_title {
		color: #007cc7;
	}

	.list_item_title {
		position: absolute;
		width: 65%;
		font-size: 14px;
		font-weight: 700;
		left: 0px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		text-align: left;
	}

	.list_item_time {
		position: absolute;
		width: 30%;
		font-size: 14px;
		font-weight: 700;
		right: 0px;
		text-align: right;
	}

	.list_item1 {
		position: relative;
		width: 90%;
		left: 5%;
		height: 68px;
		line-height: 70px;
		cursor: pointer;
		border-top-style: solid;
		border-top-width: 1px;
		border-top-color: #007cc7;
	}

	.list_item1:hover .list_item1_title {
		color: #007cc7;
	}

	.list_item1_title {
		position: absolute;
		width: 75%;
		font-size: 14px;
		font-weight: 700;
		right: 0px;
		/*overflow: hidden;*/
		/*white-space: nowrap;*/
		/*text-overflow: ellipsis;*/
		overflow: hidden;
		/* 三行显示省略号 */
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		text-align: left;
		line-height: 20px;
		top: 14px
	}

	.list_item1_time1 {
		position: absolute;
		width: 20%;
		font-size: 30px;
		font-weight: 900;
		left: 0px;
		line-height: 30px;
		top: 5px;
		color: #007cc7;
		text-align: center;
	}

	.list_item1_time2 {
		position: absolute;
		width: 20%;
		font-size: 14px;
		font-weight: 700;
		left: 0px;
		/*line-height: 20px;*/
		bottom: 10px;
		line-height: 14px;
		text-align: center;
	}

	.ptfu {
		position: relative;
		width: 100%;
		margin-top: 30px;
	}

	.ptfu_bg {
		width: 100%;
		height: auto;
	}

	.ptfu_title {
		position: absolute;
		color: white;
		top: 5%;
		font-size: 20px;
		font-weight: 600;
		left: 10%;
		border-left-style: solid;
		border-left-width: 5px;
		border-left-color: white;
		padding-left: 10px;
	}

	.ptfu_list {
		position: absolute;
		left: 12%;
		top: 15%;
		height: 30%;
		width: 76%;
		display: flex;
		flex-wrap: wrap;
	}

	.ptfu_list_item {
		position: relative;
		width: 28%;
		height:100%;
		border-radius: 10px;
		margin-left: calc(16% / 4);
		overflow: hidden;
		border-style: solid;
		border-width: 1.5px;
		border-color: white;
		cursor: pointer;
	}

	.ptfu_list_item_img {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 10%;
		top: 15%;
	}

	.ptfu_list_item_title {
		position: absolute;
		line-height: 30px;
		font-size: 18px;
		left: 25%;
		top: 15%;
		font-weight: 700;
		color: white;
	}

	.ptfu_list_item_text {
		position: absolute;
		font-size: 14px;
		width: 80%;
		left: 10%;
		top: 40%;
		color: white;
		word-wrap: break-word;
	}

	.ptfu_logo {
		position: absolute;
		width: 30%;
		height: auto;
		left: 10%;
		bottom: 15%;
	}

	.ptfu_phone {
		position: absolute;
		left: 10%;
		font-size: 35px;
		font-weight: 700;
		color: white;
		bottom: 25%;
	}

	.zcy {
		position: relative;
		width: 100%;
		height: 400px;
		margin-top: 30px;
		cursor: pointer;
		margin-bottom: 50px;
	}

	.zcy_title {
		width: 20%;
		margin-left: 40%;
		text-align: center;
		font-size: 18px;
		font-weight: 700;
		border-bottom-style: solid;
		border-bottom-width: 3px;
		border-bottom-color: #007cc7;
		line-height: 50px;
	}

	.zcy_form {
		position: relative;
		width: 60%;
		height: 350px;
		margin-top: 20px;
		background-color: white;
		margin-left: 20%;
	}

	.zcy_form_img {
		position: absolute;
		top: 20px;
		width: 25%;
		height: 310px;
		left: 30%;
		background-color: #007cc7;
		border-radius: 10px;
	}

	.zcy_form_name {
		position: absolute;
		top: 40px;
		width: 35%;
		left: 60%;
		font-size: 18px;
		font-weight: 700;
		border-left-style: solid;
		border-left-width: 5px;
		border-left-color: #007cc7;
		padding-left: 10px;
	}

	.zcy_form_text {
		position: absolute;
		top: 90px;
		width: 35%;
		left: 60%;
		font-size: 14px;
	}

	.zcy_form_text1 {
		line-height: 25px;
	}

	.zcy_form_bg1 {
		position: absolute;
		top: 20px;
		width: 25%;
		height: 310px;
		left: 20px;
		background-color: #007cc7;
		border-radius: 10px;
		text-align:center
	}

	.zcy_form_bg1_logo {
		margin-top: 10%;
		width: 50%;
		height: auto;
	}

	.zcy_form_bg1_text1 {
		width: 100%;
		font-weight: 700;
		font-size: 15px;
		color: white;
		margin-top: 20%;
	}
	.zcy_form_bg1_text2 {
		width: 100%;
		font-weight: 700;
		color: white;
		font-size: 12px;
		margin-top: 15%;
	}

	.left_gozc {
		cursor: pointer;
		position: fixed;
		top: 150px;
		right: 60px;
		width: 40px;
		height: 40px;
		z-index: 999;
	}

	.left_gozc_bg {
		cursor: pointer;
		position: fixed;
		top: 170px;
		right: 40px;
		width: 80px;
		height: 100px;
		z-index: 999;
		background-color: #85d6da;
	}

	.left_wx {
		position: fixed;
		top: 290px;
		right: 50px;
		width: 60px;
		height: 60px;
		z-index: 999;
	}

	.left_wx:hover + .left_wx_1{
		display: block;
		/*opacity: 1*/
	}

	.left_wx_1 {
		position: fixed;
		top: 290px;
		right: 130px;
		width: 200px;
		height: auto;
		z-index: 999;
		/*opacity: 0;*/
		display: none;
	}

	.left_dy {
		position: fixed;
		top: 380px;
		right: 50px;
		width: 60px;
		height: 60px;
		z-index: 999;
	}

	.left_dy:hover + .left_dy_1{
		/*opacity: 1;*/
		display: block;
	}

	.left_dy_1 {
		position: fixed;
		top: 380px;
		right: 130px;
		width: 200px;
		height: auto;
		z-index: 998;
		/*opacity: 0;*/
		display: none;
	}
</style>
